// import Vue from 'vue';
import EspecialidadeLib from '../../libs/EspecialidadesLib'

const DBEspecialidades = {
    namespaced: true,
    state: () => ({

    }),
    actions: {
        async refreshEspeciadalides() {
            let especialidades = (await EspecialidadeLib.getAPI());
            // console.log('especia', especialidades)
            localStorage.setItem('especialidades', JSON.stringify(especialidades));

        },
        async getEspecialidade({ state }, payload) {
            state;
           //  console.log('aaaa', payload);
            const lista = JSON.parse(localStorage.getItem("especialidades"));
           // console.log(lista);
            if (!payload) {
                return lista;
            } else {
                return lista.find(x => x.id == payload);
            }
        }
    }

}

export default DBEspecialidades