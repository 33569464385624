import axios from '../utils/axios';


const RelatorioVendasPremiadas = async (data, usuario, sintetico = false) => {

    try {
        let result = (await axios.post("/relatorios/vendasPremiadas", { data, usuario, sintetico })).data;
        return result;
    } catch (err) {
        console.log("Erro ao relatório vendas premiadas", err);
        return null;
    }

}

const RelatorioVendasOftalmo = async (data, usuario, sintetico = false) => {

    try {
        let result = (await axios.post("/relatorios/oftalmo", { data, usuario, sintetico })).data;
        return result;
    } catch (err) {
        console.log("Erro ao relatório vendas oftalmo", err);
        return null;
    }

}


const RelatorioComparativoConsultas = async (filters) => {

    try {
        let result = (await axios.post("/relatorios/comparativoConsultas", filters)).data;
        return result;
    } catch (err) {
        console.log("Erro ao relatório vendas premiadas", err);
        return null;
    }

}


const RelatorioAproveitamentoOTC = async (filters) => {

    try {
        let result = (await axios.post("/relatorios/aproveitamento/otc", filters)).data;
        return result;
    } catch (err) {
        console.log("Erro ao relatório vendas premiadas", err);
        return null;
    }

}


const RelatorioPagamentosLucratividade = async (filters) => {

    try {
        let result = (await axios.post("/relatorios/contas_pagar/pagamentosLucratividade", filters)).data;
        return result;
    } catch (err) {
        console.log("Erro ao relatório lucratividade", err);
        return null;
    }

}

const getRelatorioFaturamento = async (filters) => {

    try {
        let result = (await axios.post("/relatorios/getRelatorioFaturamento", filters)).data;
        return result;
    } catch (err) {
        console.log("Erro ao relatório getRelatorioFaturamento", err);
        return null;
    }

}

export default {
    RelatorioVendasOftalmo,
    RelatorioVendasPremiadas,
    RelatorioComparativoConsultas,
    RelatorioAproveitamentoOTC,
    RelatorioPagamentosLucratividade,
    getRelatorioFaturamento
}