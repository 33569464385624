const menu = [
  {
    id: 0.1,
    nome: "Notificações",
    link: "notificacoes",
    icon: "far fa-bell",
    iconType: "fa",
    permissao: ["*"],
    exibe: false,
  },
  {
    id: 0,
    nome: "Dashboard",
    link: "dashboard",
    icon: "combo_chart_52px.png",
    iconType: "png",
    permissao: ["*"],
    exibe: true,
  },
  {
    id: 0,
    nome: "Anexar Fichas",
    link: "anexarFichas",
    icon: "",
    iconType: "",
    permissao: ["*"],
    exibe: false,
  },
  {
    id: 1,
    nome: "Agenda",
    link: "agenda",
    icon: "today_52px.png",
    iconType: "png",
    permissao: ["super", "admin", "user", "secretaria", "gerente"],
    exibe: true,
  },
  {
    id: 2,
    nome: "Pacientes",
    link: "pacientes",
    icon: "wheelchair_40px.png",
    iconType: "png",
    permissao: ["*"],
    exibe: true,
  },
  {
    id: 3,
    nome: "Caixa",
    link: "caixa",
    icon: "cash_register_40px.png",
    iconType: "png",
    permissao: [
      "admin",
      "atendente",
      "secretaria",
      // "medico",
      // "Entidade",
      // "laboratorio",
      //"paciente",
      "gerente",
    ],
    exibe: true,
  },
  {
    id: 4,
    nome: "Atendimento",
    link: "atendimento",
    icon: "icons8_health_checkup_40px.png",
    iconType: "png",
    permissao: ["*"],
    exibe: true,
  },
  {
    id: 8,
    nome: "Corpo Clínico",
    link: "corpo_clinico",
    icon: "medical_doctor_40px.png",
    iconType: "png",
    permissao: ["*"],
    exibe: true,
  },
  {
    id: 5,
    nome: "Telemarketing",
    link: "telemarketing/home",
    icon: "technical_support_52px.png",
    iconType: "png",
    permissao: ["*"],
    exibe: false,
    hideSubmenu: true,
    children: [
      {
        link: 'telemarketing/consultas',
        nome: "Telemarketing Consultas",
        permissao: ["*"],
        exibe: false,
      },
      {
        link: 'telemarketing/cartao',
        nome: "Telemarketing Cartões",
        permissao: ["*"],
        exibe: false,
      },
      {
        link: 'telemarketing/pos-venda',
        nome: "Telemarketing Pós Venda",
        permissao: ["*"],
        exibe: false,
      }

    ]
  },
  {
    id: 6,
    nome: "Financeiro",
    link: "#",
    icon: "sales_performance_40px.png",
    iconType: "png",
    permissao: ["admin"],
    exibe: true,
    children: [
      {
        grupo: "Contas A Receber",
        nome: "C.R. Faturas",
        link: "financeiro/contas_receber/faturas",
        icon: "fas fa-file-alt",
        iconType: "fa",
        permissao: ["super", "admin", "gerente"],
        exibe: true,
        component: "views/financeiro/ContasReceber/Faturas.vue",
      },
      {
        grupo: "Contas A Receber",
        nome: "C.R. Manutenção",
        link: "financeiro/contas_receber/manutencao",
        icon: "fas fa-hand-holding",
        iconType: "fa",
        permissao: ["super", "admin", "gerente"],
        exibe: true,
        component: "views/financeiro/ContasReceber/Faturas.vue",
      },

      {
        grupo: "Contas A Pagar",
        nome: "C.P. Faturas",
        link: "financeiro/contas_pagar/faturas",
        icon: "fas fa-file-invoice-dollar",
        iconType: "fa",
        permissao: ["super", "admin", "gerente"],
        exibe: true,
        component: "views/financeiro/ContasPagar/Faturas.vue",
      }
    ]
  },
  {
    id: 7,
    nome: "Configurações",
    link: "#",
    icon: "automation_40px.png",
    iconType: "png",
    permissao: ["admin", "gerente"],
    exibe: true,
    children: [
      {
        grupo: "Cadastros",
        nome: "Usuários",
        link: "cadastros/usuarios",
        icon: "icons8_admin_settings_male_40px.png",
        iconType: "png",
        permissao: ["super", "admin", "user", "gerente"],
        exibe: true,
        component: "views/cadastros/Usuarios.vue",
      },
      // {
      //   grupo: "Cadastros",
      //   nome: "Clinicas",
      //   link: "cadastros/clinicas",
      //   icon: "home_40px.png",
      //   iconType: "png",
      //   permissao: ["super", "admin", "user", "gerente"],
      //   exibe: true,
      // },
      {
        grupo: "Cadastros",
        nome: "Empresas",
        link: "cadastros/empresas",
        icon: "building_40px.png",
        iconType: "png",
        permissao: ["super", "admin", "user", "gerente"],
        exibe: true,
      },
      {
        grupo: "Cadastros",
        nome: "Convenios",
        link: "cadastros/convenios",
        icon: "condo_40px.png",
        iconType: "png",
        permissao: ["super", "admin", "user", "gerente"],
        exibe: true,
      },
      {
        grupo: "Cadastros",
        nome: "Especialidades",
        link: "cadastros/especialidades",
        icon: "stethoscope_40px.png",
        iconType: "png",
        permissao: ["super", "admin", "user", "gerente"],
        exibe: true,
      },
      {
        grupo: "Cadastros",
        nome: "Médicos",
        link: "cadastros/medicos",
        icon: "medical_doctor_40px.png",
        iconType: "png",
        permissao: ["super", "admin", "user", "gerente"],
        exibe: true,
      },
      {
        grupo: "Cadastros",
        nome: "Funcionários",
        link: "cadastros/funcionarios",
        icon: "user_groups_40px.png",
        iconType: "png",
        permissao: ["super", "admin", "user", "gerente"],
        exibe: true,
      },
      {
        grupo: "Cadastros",
        nome: "Laboratórios",
        link: "cadastros/laboratorios",
        icon: "hospital_3_40px.png",
        iconType: "png",
        permissao: ["super", "admin", "user", "gerente"],
        exibe: true,
      },
      {
        grupo: "Cadastros",
        nome: "Fornecedores",
        link: "cadastros/fornecedores",
        icon: "truck_40px.png",
        iconType: "png",
        permissao: ["super", "admin", "user", "gerente"],
        exibe: true,
      },
      {
        grupo: "Cadastros",
        nome: "Medicamentos",
        link: "cadastros/medicamentos",
        icon: "medical_container_40px.png",
        iconType: "png",
        permissao: ["super", "admin", "user", "gerente"],
        exibe: true,
      },
      {
        grupo: "Cadastros",
        nome: "Tipos de Divulgação",
        link: "cadastros/tipos_de_divulgacao",
        icon: "commercial_40px.png",
        iconType: "png",
        permissao: ["super", "admin", "user", "gerente"],
        exibe: true,
      },

      // {
      //     grupo: 'Cadastros',
      //     nome: "Pacientes",
      //     link: "cadastros/pacientes",
      //     icon: "add_male_user_50px.png",
      //     iconType: 'png',
      //     permissao: ["super", "admin", "user", "gerente"],
      //     exibe: true
      // },
      {
        grupo: "Cadastros",
        nome: "Agenda",
        link: "cadastros/agenda",
        icon: "calendar_40px.png",
        iconType: "png",
        permissao: ["super", "admin", 'gerente'],
        exibe: true,
      },
      {
        grupo: "Cadastros",
        nome: "Produtos / Exames / Serviços",
        link: "cadastros/exames",
        icon: "syringe_40px.png",
        iconType: "png",
        permissao: ["super", "admin", "user", "gerente"],
        exibe: true,
      },
      {
        grupo: "Cadastros",
        nome: "Anamnese",
        link: "cadastros/anamneses",
        icon: "treatment_40px.png",
        iconType: "png",
        permissao: ["super", "admin", "user", "gerente"],
        exibe: true,
      },
      {
        grupo: "Cadastros",
        nome: "Fidelização",
        link: "cadastros/fidelizacao",
        icon: "loyalty_40px.png",
        iconType: "png",
        permissao: ["super", "admin", "user", "gerente"],
        exibe: true,
      },
      {
        grupo: "Cadastros",
        nome: "Centros de Custo",
        link: "cadastros/centros_de_custo",
        icon: "us_dollar_circled_40px.png",
        iconType: "png",
        permissao: ["super", "admin", "user", "gerente"],
        exibe: true,
      },
      {
        grupo: "Cadastros",
        nome: "Categorias Contas Pagar",
        link: "cadastros/contas_pagar_categorias",
        icon: "exchange_40px.png",
        iconType: "png",
        permissao: ["super", "admin", "user", "gerente"],
        exibe: true,
      },
      {
        grupo: "Cadastros",
        nome: "Bandeiras",
        link: "cadastros/bandeiras",
        icon: "card_security_40px.png",
        iconType: "png",
        permissao: ["super", "admin", "user", "gerente"],
        exibe: true,
      },
      {
        grupo: "Cadastros",
        nome: "Estabelecimentos",
        link: "cadastros/estabelecimentos",
        icon: "online_store_40px.png",
        iconType: "png",
        permissao: ["super", "admin", "user", "gerente"],
        exibe: true,
      },
      {
        grupo: "Ação",
        nome: "Unificar Cadastros",
        link: "unificarCadastros",
        icon: "fa fa-arrows-alt-h",
        iconType: "fa",
        permissao: ["super", "admin", "gerente"],
        exibe: true,
      },

      {
        grupo: "Ação",
        nome: "Correção Custos | Vendas",
        link: "corrigirCustoVenda",
        icon: "fas fa-search-dollar",
        iconType: "fa",
        permissao: ["super", "admin", "gerente"],
        exibe: true,
      },
    ],
  },
  {
    id: 9,
    nome: "Relatórios ",
    link: "#",
    icon: "fa fa-print",
    iconType: "fa",
    permissao: ["super", "admin", "user", "gerente"],
    exibe: true,
    children: [
      {
        grupo: "Relatórios",
        nome: "Relatório de Aniversários",
        link: "relatorios/aniversarios",
        icon: "christmas_gift_40px.png",
        iconType: "png",
        permissao: ["super", "admin", "user", "gerente"],
        exibe: true,
      },
      {
        grupo: "Relatórios",
        nome: "Comparativo de Consultas",
        link: "relatorios/comparativo/consultas",
        icon: "fa fa-stethoscope",
        iconType: "fa",
        permissao: ["super", "admin", "gerente"],
        exibe: true,
      },
      {
        grupo: "Relatórios",
        nome: "Guias Médicas",
        link: "relatorios/guias",
        icon: "fas fa-file-alt",
        iconType: "fa",
        permissao: ["super", "admin", "gerente"],
        exibe: true,
      },
      {
        grupo: "Relatórios",
        nome: "Aproveitamento/Otc",
        link: "relatorios/aproveitamento/otc",
        icon: "fas fa-file-alt",
        iconType: "fa",
        permissao: ["super", "admin", "gerente"],
        exibe: true,
      },
      {
        grupo: "Relatórios",
        nome: "Pagamentos X Lucro",
        link: "relatorios/contas_pagar/pagamentosLucro",
        icon: "fa fa-file-invoice",
        iconType: "fa",
        permissao: ["super", "admin", "gerente"],
        exibe: true,
      },
      {
        grupo: "Relatórios",
        nome: "Faturamento",
        link: "relatorios/faturamento",
        icon: "fas fa-dollar-sign",
        iconType: "fa",
        permissao: ["super", "admin", "gerente"],
        exibe: true,
      },
      {
        grupo: "Relatórios",
        nome: "Vendas X Convênio",
        link: "relatorios/vendasConvenio",
        icon: "condo_40px.png",
        iconType: "png",
        permissao: ["super", "admin", "gerente"],
        exibe: true,
      },

    ],
  },
  {
    id: 998,
    link: "print",
    permissao: ["*"],
    exibe: false,
  },

  // {
  //   id: 99,
  //   nome: "Sair",
  //   link: "logout",
  //   icon: "shutdown_40px.png",
  //   iconType: "png",
  //   permissao: ["*"],
  //   exibe: true,
  //   rtl: true,
  // },
];

export default menu;
