import axios from '@/utils/axios.js';
import stor from '../store'
const get = async () => {
    return await stor.dispatch('DBEspecialidades/getEspecialidade') || [];
}
const getAPI = async () => {
    let especialidades = [];
    await axios.get('/especialidades/get').then((result) => {
        especialidades = result.data
    }).catch((err) => {
        console.log('erro ao buscar especialidades', err);
    });
    return especialidades.data;
}
const store = async (data) => {
    let ret = (await axios.post('/especialidades/store', data)).data;
    await stor.dispatch('DBEspecialidades/refreshEspeciadalides');
    return ret;
}
const del = async (data) => {
    let ret = (await axios.post('/especialidades/delete', data)).data;
    await stor.dispatch('DBEspecialidades/refreshEspeciadalides');
    return ret;
}
//ecma script
export default {
    get, store, del, getAPI
}