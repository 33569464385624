<template>
  <div class="container">
    <div class="row">
      <div
        class="col d-flex flex-column align-items-center"
        v-if="!user.isMedico"
      >
        <b-btn
          to="/anexarFichas"
          size="lg"
          variant="primary"
          class="rounded mt-3"
        >
          <i class="fas fa-camera"></i> Anexar Ficha
        </b-btn>
        <small class="text-muted">
          * Botão provisório para anexar fichas antigas na ficha do paciente
        </small>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="titlePerson">
          <div class="photo">
            <img src="@/assets/img/icones/menu/technical_support_52px.png" />
          </div>
          <div class="text">Seja bem vindo(a) {{ user.nome }}</div>
        </div>
      </div>

      <div class="col-12 pt-0 pl-5 pr-5">
        <div class="row pt-0">
          <div class="col-12 pt-0 pl-4 pr-4">
            <div class="row pt-0">
              <div class="col-12 col-sm-6 pt-0 pr-5 pl-5 pt-0">
                <div class="box">
                  <div class="title">
                    <b-icon-calendar-day /> Próximas Agendas
                  </div>
                  <div class="cont">
                    <div
                      class="item mb-sm-0 mb-3"
                      v-for="agenda in listAgendas"
                      :key="'agenda' + agenda.id"
                    >
                      <div class="row">
                        <div class="col-12 col-sm-3 pr-1 text-sm-left">
                          <b-icon-calendar />
                          {{ agenda.data | moment("DD/MM") }}
                          <br />
                          <b-icon-clock /> {{ agenda.hora_inicio }}
                        </div>
                        <div class="col-12 col-sm-5 pl-1 pr-1">
                          <i class="fa fa-user-md" /> {{ agenda.EntidadeNome }}
                          <br />
                          <i class="fa fa-stethoscope" />
                          {{ agenda.especialidadeNome }}
                        </div>
                        <div class="col-12 col-sm-4 pl-0">
                          <b-icon-shop />
                          {{
                            agenda.consultorio === 1
                              ? "Consultório"
                              : agenda.clinic
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="foot">Exibir Todas</div>
                </div>
              </div>
              <div class="col-12 mt-sm-0 mt-3 d col-sm-6 pt-0 pr-5 pt-0">
                <div class="box" style="height: 150px">
                  <div class="title">
                    <b-icon-eyeglasses /> Oftalmo/Tonometria de
                    {{ user.nome.split(" ")[0] }}
                    <div class="campoDireita">
                      <b-select
                        :options="mes_ano"
                        :value-field="`value`"
                        :text-field="`text`"
                        v-model="oftalmo_mes"
                      />
                    </div>
                  </div>
                  <div class="cont p-2">
                    <Resumo
                      :lista="lista_premiada"
                      :detalhes="vendas_premiadas_detalhes"
                    />
                  </div>
                </div>
                <div class="box">
                  <div class="title">
                    <b-icon-gift /> Vendas Premiadas de
                    {{ user.nome.split(" ")[0] }}
                    <div class="campoDireita">
                      <b-select
                        :options="mes_ano"
                        :value-field="`value`"
                        :text-field="`text`"
                        v-model="vendas_premiada_mes"
                      />
                    </div>
                  </div>
                  <div class="cont p-2">
                    <Resumo
                      :lista="lista_premiada"
                      :detalhes="vendas_premiadas_detalhes"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AgendaLib from "../libs/AgendasLib.js";
import moment from "moment";
import RelatoriosLib from "../libs/RelatoriosLib";
import Resumo from "../components/Relatorios/VendasPremiadas/Resumo.vue";
// import Calendar from "../components/Dashboard/Calendar.vue";
export default {
  components: {
    Resumo,
  },
  props: {},
  mounted() {
    this.carregar();
  },
  data() {
    return {
      user: this.$store.state.auth.user,
      listAgendas: [],
      vendas_premiada_mes: null,
      oftalmo_mes: null,
      loading_premiada: false,
      lista_premiada: [],
      vendas_premiadas_detalhes: [],
      lista_oftalmo: [],
      lista_oftalmo_detalhes: [],
    };
  },
  watch: {
    vendas_premiada_mes: function () {
      this.carregarPremiadas();
    },
     oftalmo_mes: function () {
      this.carregarOftalmos();
    },
  },
  computed: {},
  methods: {
    async carregarOftalmos() {
      this.lista_oftalmo = [];
      this.lista_oftalmo_detalhes = [];
      this.loading_premiada = true;

      let res = await RelatoriosLib.RelatorioVendasOftalmo(
        this.oftalmo_mes,
        this.user,
        true
      );

    

      // if (res && res.data && res.data.length > 0) {
     
    
      // }
      console.log("res oftalmos", res);

      this.loading_premiada = false;
    },
    async carregarPremiadas() {
      this.lista_premiada = [];
      this.vendas_premiadas_detalhes = [];
      this.loading_premiada = true;

      let res = await RelatoriosLib.RelatorioVendasPremiadas(
        this.vendas_premiada_mes,
        this.user,
        true
      );

      if (res && res.data && res.data.length > 0) {
        this.lista_premiada = res.data.reduce((ret, vl) => {
          let has = ret.find((x) => x.tp == vl.tp);
          if (!has) {
            ret.push({
              tp: vl.tp,
              list: [vl],
              qtd: vl.qtd,
            });
          } else {
            has.list.push(vl);
            has.qtd += vl.qtd;
          }
          return ret;
        }, []);

        let resdet = await RelatoriosLib.RelatorioVendasPremiadas(
          this.vendas_premiada_mes,
          this.user,
          false
        );
        this.vendas_premiadas_detalhes = resdet.data.reduce((ret, vl) => {
          let has = ret.find((x) => x.tipo === vl.tipo);
          if (!has) {
            ret.push({
              tipo: vl.tipo,
              qtd: 1,
              lista: [vl],
            });
          } else {
            has.qtd++;
            has.lista.push(vl);
          }
          return ret;
        }, []);
      }
      // console.log("res", res);

      this.loading_premiada = false;
    },
    async carregar() {
      this.listAgendas = await AgendaLib.get(
        moment().format("YYYY-MM-DD"),
        moment().add(2, "d").format("YYYY-MM-DD")
      );
      console.log(this.listAgendas);
      this.mes_ano = [];
      for (let i = 1; i <= 16; i++) {
        let m = i;
        let y = 0;
        if (m > 12) {
          y = 1;
          m = m - 12;
        }

        let ms = moment(
          moment().format(
            (y == 1 ? moment().add(y, "years").format("YYYY") : "YYYY") +
              "-" +
              (m < 10 ? "0" : "") +
              m +
              "-01"
          )
        );
        this.mes_ano.push({
          value: {
            month: ms.format("MM"),
            year: ms.format("YYYY"),
          },
          text: ms.format("MMMM / YYYY"),
        });
      }
      this.vendas_premiada_mes =
        this.mes_ano[
          parseInt(moment().format("MM")) -
            parseInt(moment().format("DD") < 26 ? 1 : 0)
        ].value;

        this.oftalmo_mes =
        this.mes_ano[
          parseInt(moment().format("MM")) -
            parseInt(moment().format("DD") < 26 ? 1 : 0)
        ].value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/default.scss";
.box {
  height: 400px;
  position: relative;
  transition: 0.2s all ease;

  .title {
    width: 100%;
    background-color: #fafafa;
    color: #333;
    padding: 10px;

    transition: 0.2s all ease;
    .campoDireita {
      max-width: 150px;
      font-size: 11px;
      position: absolute;
      right: 5px;
      top: 5px;
      select {
        font-size: 10px !important;
        padding: 5px;
        height: 25px;
      }
    }
  }
  .cont {
    position: absolute;
    top: 43px;
    bottom: 30px;
    left: 0px;
    right: 0px;
    background: #fff;
    display: flex;
    flex-flow: row wrap;
    // padding: 10px;
    transition: 0.2s all ease;
    align-content: flex-start;
    overflow: hidden;
    overflow-y: auto;

    .section {
      width: 100%;
      height: 100%;
    }

    .item {
      flex: 100%;
      border-bottom: 1px solid rgba(204, 204, 204, 0.185);
      height: 1fr;
      cursor: pointer;
      transition: 0.2s all ease;
      padding: 12px;
      padding-left: 20px;

      font-size: 12px;

      &:hover {
        border-left: 10px solid #ccc;
        border-bottom-color: #ccc;
      }
    }
  }
  .foot {
    position: absolute;
    width: 100%;
    background: #ccc;

    bottom: 0px;
    cursor: pointer;
    padding: 3px;

    &:hover {
      background-color: #ddd;
    }
  }
}
.titlePerson {
  text-align: left;
  padding: 20px;
  display: flex;
  .photo {
    background-color: #ffffff;
    padding: 10px;
    border-radius: 100%;
    width: 90px;
    height: 90px;
    text-align: center;
    display: flex;
    align-content: center;
    color: black;
    img {
      filter: invert(30);
    }
  }
  .text {
    background-color: #d8d8d8;
    width: 100%;
    padding: 10px;
    color: rgb(102, 102, 102);
    font-size: 20px;
    height: 65px;
    margin-top: 15px;
    padding-left: 40px;
    margin-left: -20px;
    z-index: -1;
    // border-top-right-radius: 40px;
  }
}
</style>
