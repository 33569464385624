<template>
  <div class="topo">
    <div class="conteudoMenuOpen" v-if="menuVisivel"></div>

    <div
      class="botaoMenu d-sm-none"
      :class="{
        menuOpen: menuVisivel,
        botaoMenuHover: $store.state.menuVisivel,
      }"
      @click="toogleMenu()"
    >
      <i class="fas fa-bars" v-show="!menuVisivel"></i>
      <i class="fas fa-times" v-show="menuVisivel"></i>
    </div>
    <div class="logo d-none d-sm-block">
      <img src="@/assets/social_logo.jpg" />
    </div>
    <div class="profile" style="line-height: 10px" @click="clickSenha">
      <b-avatar style="width: 35px; float: left" />
      <div class="d-flex justify-content-center align-items-center mb-2">
        <div class="text">
          {{ user.nome }}
        </div>
        <b-dropdown
          size="md"
          variant="link"
          toggle-class="text-decoration-none p-0 m-0"
          class="p-0"
          no-caret
          ref="btnAltSenha"
          id="btnAltSenha"
        >
          <template #button-content>
            <b-icon variant="secondary" icon="gear"></b-icon>
          </template>

          <b-dropdown-item-button
            class="mnuit"
            @click="openAlterarSenha"
            href="#"
          >
            <b-icon icon="shield-lock"></b-icon> Alterar
            senha</b-dropdown-item-button
          >
          <b-dropdown-item-button class="mnuit" href="#" @click="logout">
            <b-icon icon="box-arrow-in-left"></b-icon>
            Sair</b-dropdown-item-button
          >
        </b-dropdown>
      </div>
      <connection-status />
    </div>
    <b-modal id="alterar-senha">
      <template #modal-title>
        <b-icon icon="shield-lock"></b-icon> Alterar senha -
        <b>{{ user.nome }}</b>
      </template>

      <change-password
        @submit="emitSavePassword"
        @success="hideAlterarSenha"
        ref="changePasswordComponent"
      ></change-password>
      <template #modal-footer>
        <b-btn type="submit" @click="emitSavePassword" variant="primary">
          <i class="fas fa-pencil-alt"></i> Alterar</b-btn
        >
        <b-btn variant="secondary" @click="hideAlterarSenha">
          <i class="fas fa-ban"></i> Cancelar</b-btn
        >
      </template>
    </b-modal>
  </div>
</template>
<script>
import ConnectionStatus from "../ConnectionStatus.vue";
import ChangePassword from "../ChangePassword.vue";
export default {
  components: {
    ConnectionStatus,
    ChangePassword,
  },
  data() {
    return {
      menuVisivel: this.$store.state.menuVisivel,
      user: this.$store.state.auth.user,
    };
  },
  watch: {
    "$store.state.menuVisivel": function () {
      this.menuVisivel = this.$store.state.menuVisivel;
    },
  },
  methods: {
    toogleMenu() {
      this.$store.state.menuVisivel = !this.$store.state.menuVisivel;
    },
    emitSavePassword() {
      this.$refs.changePasswordComponent.save();
    },
    openAlterarSenha() {
      this.$bvModal.show("alterar-senha");
    },
    hideAlterarSenha() {
      this.$bvModal.hide("alterar-senha");
    },
    logout() {
      this.$swal({
        title: "Tem certeza que deseja sair?",
        text: "Você será desconectado(a) do sistema.",
        icon: "question",
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (x) => {
        if (x.isConfirmed) {
          location.href = "/logout";
        }
      });
    },
    clickSenha() {
      document.querySelector("#btnAltSenha").querySelector("button").click();
    },
  },
};
</script>
<style lang="scss">
.mnuit {
  button {
    padding: 10px !important;
    min-width: 200px;
  }
}
</style>