<template>
  <div>
    <div>
      <b-navbar toggleable="lg" type="dark" variant="primary">
        <b-navbar-brand href="/telemarketing"
          ><img
            height="20"
            src="@/assets/img/icones/menu/technical_support_52px.png"
          />Telemarketing</b-navbar-brand
        >

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item to="/telemarketing/home" :active="$route.name=='home'"><i class="fa fa-home mr-1"></i>Início</b-nav-item>
            <b-nav-item to="/telemarketing/consultas" :active="$route.name=='consultas'"><i class="fa fa-stethoscope mr-1"></i>Consultas</b-nav-item>
            <b-nav-item to="/telemarketing/cartao" :active="$route.name=='cartao'"><i class="fa fa-id-card mr-1"></i>Cartão Fidelidade</b-nav-item>
            <b-nav-item to="/telemarketing/pos-venda" :active="$route.name=='pos-venda'"><i class="fa fa-shopping-cart mr-1"></i>Pós Venda</b-nav-item>
            <b-nav-item-dropdown>
              <!-- Using 'button-content' slot -->
              <template #button-content>
                Cadastros
              </template>
              <b-dropdown-item href="#">Resultados</b-dropdown-item>
              
            </b-nav-item-dropdown>
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">


            <!-- <b-nav-item-dropdown text="Lang" right>
              <b-dropdown-item href="#">EN</b-dropdown-item>
              <b-dropdown-item href="#">ES</b-dropdown-item>
              <b-dropdown-item href="#">RU</b-dropdown-item>
              <b-dropdown-item href="#">FA</b-dropdown-item>
            </b-nav-item-dropdown> -->

            <!-- <b-nav-item-dropdown right>
              <template #button-content>
                <em>User</em>
              </template>
              <b-dropdown-item href="#">Profile</b-dropdown-item>
              <b-dropdown-item href="#">Sign Out</b-dropdown-item>
            </b-nav-item-dropdown> -->
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
  
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  mounted() {
    console.log("rout", this.$route)
  },
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
