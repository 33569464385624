<template>
  <div id="app" @keydown.esc="closeSwal">
    <div
      class="loading text-right"
      v-if="loading"
      small
      style="
        position: fixed;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background: rgba(255, 255, 255, 0.5) !important;
        z-index: 999999;
        padding: 20px;
      "
    >
      <b-spinner small variant="secondary" /> carregando...
    </div>
    <router-view />
  </div>
</template>
<script>
export default {
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  mounted() {
    this.loading = this.$store.state.globalLoading;
  },
  data() {
    return {
      loading: this.$store.state.globalLoading,
    };
  },
  watch: {
    "$store.state.globalLoading": function () {
      this.loading = this.$store.state.globalLoading;
      // console.log("loading", this.loading);
    },
  },
  methods: {
    myEventHandler() {
      if (window.innerWidth > 575 && this.$store.state.menuVisivel) {
        this.$store.state.menuVisivel = false;
      }
      if (window.innerWidth < 575 && !this.$store.state.menuVisivel) {
        // this.$store.state.menuVisivel = true;
      }
    },
    closeSwal() {
      
      this.$swal.close();
    },
  },
};
</script>