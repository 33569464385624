<template>
  <div class="container">
    <Menu />
     
      <div class="conteudo">
        <Topo />
        <div @click="$store.state.menuVisivel = false"></div>
        <router-view ></router-view>
      </div>
    
  </div>
</template>
<script>
import "@/assets/scss/layout.scss";
import Topo from "@/components/Layout/topo";
import Menu from "@/components/Layout/menu";
export default {
  components: {
    Topo,
    Menu,
  },
  methods: {},
};
</script>