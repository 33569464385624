import axios from "@/utils/axios.js";

const get = async (
  dataDe = "",
  dataAte = "",
  Entidade = "",
  especialidade = "",
  clinica = "",
  withoutConsultorio = false,
  id = null
) => {
  // console.log('datas', dataDe, dataAte)
  // console.log('Entidade', Entidade)
  // console.log('especialidade', especialidade)
  console.log("id", id);
  let agendas = [];

  await axios
    .get("/agendas/get", {
      params: { dataDe, dataAte, Entidade, especialidade, clinica, withoutConsultorio, id },
    })
    .then((result) => {
      agendas = result.data;
    })
    .catch((err) => {
      console.log("erro ao buscar as agendas", err);
    });

  return agendas.data;
};
const store = async (data) => {
  // console .log('data no store',data)
  // console.log(data);
  return (await axios.post("/agendas/store", data)).data;
};

const lancarMultiplos = async (data) => {
  // console .log('data no store',data)
  // console.log(data);
  return (await axios.post("/agendas/lancarMultiplo", data)).data;
};

const del = async (data) => {
  // console.log(data);
  return await axios.post("/agendas/delete", data);
};

const buscaHorarioPorPaciente = async(data)=>{
  return ((await axios.post("/horarios/buscaHorarioPorPaciente", data)).data).data;
}

//ecma script
export default {
  get,
  store,
  lancarMultiplos,
  del,
  buscaHorarioPorPaciente
};
