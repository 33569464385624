import Vue from 'vue';
import axios from '@/utils/axios';
import store from '../index'


const AuthModule = {
     namespaced: true,
     state: () => ({
          user: {},
          auth: false
     }),
     actions: {
          logout: async ({ state }) => {
               await axios.get('auth/logout', { params: { token: state.user.token }}).then(() => {
               }).catch(() => { });
               localStorage.removeItem('token')
               localStorage.removeItem('user')
               console.log('logout!')
               state.user = {};
               return true;
          },
          getUser: ({ state }, payload) => {
               state.user = payload;
          },
          auth: async ({ state, dispatch }, payload) => {
               return await axios.post(`/auth/login`, payload).then(async (result) => {

                    state.user.token = result.data.token;
                    state.user.refreshToken = result.data.refreshToken;
                    localStorage.setItem('token', state.user.token);
                    localStorage.setItem('refreshToken', state.user.refreshToken);
                    localStorage.setItem('user', state.user);
                    state.auth = true;
                    axios.defaults.headers.common['Authorization'] = `Bearer ${state.user.token}`;

                    return await axios.get('auth/me').then(async (result) => {
                         await dispatch('isAuthenticated');
                         return { success: true, user: result.data };
                    }).catch((err) => {
                         console.log(err);
                         return { success: false, user: null };
                    });
               }).catch((error) => {
                    console.log("error", error);
                    return false;
               });
          },
          isAuthenticated: async ({ state, rootState }) => {
               rootState.globalLoading = true;

               state.user.token = localStorage.getItem('token');
               state.user.refreshToken = localStorage.getItem('refreshToken');
               // console.log('sts', Vue.prototype.$socket.connected)
               Vue.prototype.$socket.disconnect();
               // console.log('sts', Vue.prototype.$socket.connected, Vue.prototype.$socket)

               Vue.prototype.$socket.io.engine.opts.query.token = state.user.token ;
               Vue.prototype.$socket.io.opts.query = { token: state.user.token  };
               Vue.prototype.$socket.connect();
               // console.log('sts', Vue.prototype.$socket.connected)

               // console.log('vue sok', Vue.prototype.$socket.io.engine.uri)

               if (typeof (state.user.token) == "undefined" || state.user.token == null || state.user.token == "") {
                    state.auth = false;
                    rootState.globalLoading = false;

                    return false;
               } else {

                    if (typeof (state.user) != "undefined" && typeof (state.user.perfil) != "undefined") {
                         state.auth = true;
                         rootState.globalLoading = false;

                         return true;
                    } else {
                         axios.defaults.headers.common['Authorization'] = `Bearer ${state.user.token}`;
                         return await axios.get('auth/me').then(async (result) => {
                              state.user = { ...result.data, ...state.user };
                              // axios.get('/auth/empresa').then((data) => {
                              //      rootState.empresas = data.data;
                              // });
                              store.dispatch("DBEspecialidades/refreshEspeciadalides");
                              store.dispatch("DBEmpresas/refreshEmpresas");
                              rootState.globalLoading = false;


                              return true;
                         }).catch((err) => {
                              console.log(err)
                              state.user = {};
                              localStorage.removeItem('token')
                              localStorage.removeItem('user')
                              rootState.globalLoading = false;

                              return false;
                         });
                    }
               }
          },
     }

}

export default AuthModule
