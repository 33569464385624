import axios from 'axios';
import store from '../store/index';


console.log("creating axios", store.state.apiURL)
const instance = axios.create({
  baseURL: store.state.apiURL
});



instance.interceptors.request.use(config => {
  if (store.state.token) {
    axios.defaults.headers.common['Authorization'] =
      'Bearer ' + store.state.auth.user.token;
  }
  return config;
});

instance.interceptors.response.use(res => {
  
  store.state.connection = true;
  store.commit('changeConnection', true)
  return res;
}, error => {

  console.log("response axios",  error)

  if (!error.response) {
    // store.commit("changeDefaultAPIUrl", store.state.apiBKPURL)
    // console.log("changed api from ",store.state.apiURL, store.state.apiBKPURL);
    // instance.defaults.baseURL = store.state.apiBKPURL
  }
  console.log('error api', typeof error, error.response)
 
  if (error.response) {
    store.commit('changeConnection', true)
    store.state.connection = true;
  } else {
    store.commit('changeConnection', false)
    store.state.connection = false;
  }
  throw error
});

export default instance;