<template>
  <div class="connectionStatus">
    <i
      class="fa fa-dot-circle"
      :class="{ 'text-success': st, 'text-danger': !st }"
    />
    Servidor {{ st == null ? "Indefinido" : st == false ? "Offline" : "Online" }}
    {{$store.state.apiBKPURL === $store.state.apiURL?' - BKP':''}}
  </div>
</template>

<script>
// import axios from "../utils/axios";

export default {
  data() {
    return {
      status: null,
    };
  },
  mounted() {
    // setInterval(() => {
    //   axios
    //     .get("/version")
    //     .then((res) => {
    //       this.$store.state.version = res.data.version;
    //     })
    //     .catch((er) => {
    //       console.log("api off", er);
    //     });
    // }, 2000);
  },

  computed: {
    st: function () {
      return this.$store.state.connection;
    },
  },
};
</script>

<style lang="scss" scoped>
.connectionStatus {
  float:right;
  font-size: 6px;  
  font-weight: bold;
  // z-index: 2;
}
</style>