<template>
  <b-form class="row" @submit.prevent="$emit('submit')">
    <div class="col-12">
      <b-form-group label="Senha antiga">
        <b-form-input
          v-model="changePasswordRequest.oldPassword"
          type="password"
          :state="validate('oldPassword').status"
        ></b-form-input>
        <b-form-invalid-feedback>
          <ul>
            <li
              v-for="msg of validate('oldPassword').messages"
              :key="`${validate('oldPassword').messages.indexOf(msg)}_${msg}`"
            >
              {{ msg }}
            </li>
          </ul>
        </b-form-invalid-feedback>
      </b-form-group>
    </div>
    <div class="col-12">
      <b-form-group label="Nova senha">
        <b-form-input
          v-model="changePasswordRequest.newPassword"
          type="password"
          :state="validate('newPassword').status"
        ></b-form-input>
        <b-form-invalid-feedback>
          <ul>
            <li
              v-for="msg of validate('newPassword').messages"
              :key="`${validate('newPassword').messages.indexOf(msg)}_${msg}`"
            >
              {{ msg }}
            </li>
          </ul>
        </b-form-invalid-feedback>
      </b-form-group>
    </div>
    <div class="col-12">
      <b-form-group label="Confirmar nova senha">
        <b-form-input
          v-model="changePasswordRequest.confirmationNewPassword"
          type="password"
          :state="validate('confirmationNewPassword').status"
        ></b-form-input>
        <b-form-invalid-feedback>
          <ul>
            <li
              v-for="msg of validate('confirmationNewPassword').messages"
              :key="
                `${validate('confirmationNewPassword').messages.indexOf(
                  msg
                )}_${msg}`
              "
            >
              {{ msg }}
            </li>
          </ul>
        </b-form-invalid-feedback>
      </b-form-group>
    </div>
  </b-form>
</template>

<script>
import UsuariosLib from "../libs/UsuariosLib";
export default {
  data() {
    return {
      changePasswordRequest: {
        oldPassword: null,
        newPassword: null,
        confirmationNewPassword: null,
      },
      validations: [],
    };
  },
  methods: {
    validate(key) {
      let idxValidation = this.validations.findIndex((v) => v.key === key);
      const validators = {
        oldPassword: (value) => {
          let messages = [];

          if (typeof value === "string" && value) {
            if (value.trim().length < 6) {
              messages.push(
                "A senha antiga deve conter no minimo 6 caractéres"
              );
            }
          } else {
            messages.push("A senha antiga não pode estar vazia.");
          }
          return {
            status: value === null ? value : messages.length === 0,
            messages,
          };
        },
        newPassword: (value) => {
          let messages = [];

          if (typeof value === "string" && value) {
            if (value.trim().length < 6) {
              messages.push("A nova senha deve conter no minimo 6 caractéres");
            }
          } else {
            messages.push("A nova senha não pode estar vazia.");
          }
          return {
            status: value === null ? value : messages.length === 0,
            messages,
          };
        },
        confirmationNewPassword: (value) => {
          let messages = [];

          if (typeof value === "string" && value) {
            if (value.trim().length < 6) {
              messages.push("A senha deve conter no minimo 6 caractéres");
            }
            if (value !== this.changePasswordRequest.newPassword) {
              messages.push(
                "A senha de confirmação deve ser igual a nova senha!"
              );
            }
          } else {
            messages.push("A senha de confirmação não pode estar vazia.");
          }
          return {
            status: value === null ? value : messages.length === 0,
            messages,
          };
        },
      };

      if (idxValidation !== -1) {
        this.validations[idxValidation] = {
          key,

          ...validators[key](this.changePasswordRequest[key]),
        };
        return this.validations[idxValidation];
      } else if (Object.keys(validators).includes(key)) {
        const validated = {
          key,

          ...validators[key](this.changePasswordRequest[key]),
        };
        this.validations.push(validated);
        idxValidation = this.validations.findIndex((v) => v.key === key);
        return this.validations[idxValidation];
      } else {
        return { key, status: false, messages: ["Inválido."] };
      }
    },
    async save() {
      try {
        let hasErrors = this.validations
          .filter((v) => !v.status)
          .map((v) => v.messages);
        if (hasErrors.length === 0) {
          //
          try {
            const result = await UsuariosLib.changePassword(this.user?.id,this.changePasswordRequest);
            if (result) {
              this.showToast("success", "Senha alterada com sucesso!", 3500);
              this.$emit("success", result);
            }
          } catch (error) {
            console.log('--> error: ', error);
            const errorTreated = error?.response?.data?.messages;
            let errorDesc = errorTreated.reduce((ret, vl) => {
              ret += vl + "<hr/>";
              return ret;
            }, "");
            this.$swal({
              title: "Atenção",
              html: `<b>Os detalhes a seguir precisam de atenção</b><hr/><span class='text-left'>${errorDesc}</span>`,
              icon: "error",
              allowEscapeKey: true,
            });
          }
        } else {
          let errorDesc = hasErrors.reduce((ret, vl) => {
            ret += vl + "<hr/>";
            return ret;
          }, "");

          this.$swal({
            title: "Atenção",
            html: `<b>Os detalhes a seguir precisam de atenção</b><hr/><span class='text-left'>${errorDesc}</span>`,
            icon: "error",
            allowEscapeKey: true,
          });
        }
      } catch (error) {
        //
        this.$swal({
          title: "Atenção",
          html: `<b>Erro na operação</b>`,
          icon: "error",
          allowEscapeKey: true,
        });
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
