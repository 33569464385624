import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import filters from './filters/index.js';
// import VueMask from 'v-mask';
import VueTheMask from 'vue-the-mask'
import VueDatePicker from 'vue2-datepicker'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
// import VueSelect from 'vue-select'
import vSelect from 'vue-select'
import VueRx from 'vue-rx'
import VuejsClipper from 'vuejs-clipper'
import VCalendar from 'v-calendar';
import money from 'v-money';
// import Vuelidate from 'vuelidate'

import ProductZoomer from 'vue-product-zoomer'

import bBtnExibeLogs from './components/common/ExibeLogs/b-btn-exibe-logs'


Vue.config.productionTip = false


import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-select/dist/vue-select.css';
import './assets/scss/app.scss';
import 'vue2-datepicker/index.css';
import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code', VueQRCodeComponent)
import "regenerator-runtime/runtime.js";
import VueHtml2Canvas from 'vue-html2canvas';

Vue.use(VueHtml2Canvas);


Vue.component('v-select', vSelect)
Vue.use(VueRx)

// Vue.use(Vuelidate)
Vue.component('b-btn-exibelog', bBtnExibeLogs)
Vue.use(money, { precision: 4 })
Vue.use(VuejsClipper)
Vue.use(VueDatePicker)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(BootstrapVueIcons)
Vue.use(filters);
Vue.use(VueSweetalert2);
// Vue.use(VueMask)
Vue.use(VueTheMask)
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});
Vue.use(ProductZoomer)
const moment = require('moment')

require('moment/locale/pt-br')
Vue.use(require('vue-moment'), {
  moment
})
import { AsyncFilterMixin } from '@tygr/vue-async-filter'
import './registerServiceWorker'


import sock from './helpers/SocketIOCreateInstance';
sock.create(Vue, store.state.apiURL);

Vue.mixin(AsyncFilterMixin)
Vue.mixin({
  data() {
    return {
      moneyMask: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
    
      pctMask: {
        prefix: "% ",
        precision: 2,
        decimal: ",",
        masked: false,
      },
      user: store.state.auth.user
    }
  },
  methods: {
    async getEspecialidade(id) {
      return (await (store.dispatch("DBEspecialidades/getEspecialidade", id))).especialidade
    },
    formatValorReal(vl) {
      console.log("formatValorReal", vl)
      if (!vl || typeof vl == 'undefined' || vl == null || vl == '') {
        vl = '0';
      }
      return parseFloat(vl.toString().replace("R$", "").replace(".", "").replace(",", ".").trim());
    },
    formatFloatToReal(vl, prefix = "R$ ") {
      if (!vl || typeof vl == 'undefined' || vl == null || vl == '') {
        vl = 0;
      }
      return prefix + parseFloat(vl).toFixed(2).replace(",", "").replace(".", ",");
    },
    showToast(
      icone = "",
      message = "",
      time = 1500,
      description = "",
      confirmButton = false,
      position = "top-end"
    ) {
      this.$swal.fire({
        title: message,
        icon: icone,
        toast: true,
        position: position,
        timer: time,
        showConfirmButton: confirmButton,
        timerProgressBar: true,
        text: description,

        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });
    },

    async toastConfirmacao(callbackYes, callbackNo, title = "", text = "", icon = "", confirmButtonText = "", cancelButtonText = "") {
      this.$swal
        .fire({
          title: title != "" ? title : `Você tem certeza que deseja excluir?`,
          icon: icon != "" ? icon : "warning",
          text: text ? text : "",
          showCancelButton: true,
          confirmButtonText: confirmButtonText ? confirmButtonText : "Sim. Excluir!",
          cancelButtonText: cancelButtonText ? cancelButtonText : "Não.",
        })
        .then(async (res) => {
          if (res.isConfirmed) {
            callbackYes ? await callbackYes() : {}
          } else {
            callbackNo ? await callbackNo() : {}
          }
        });
    },
  },

})



// Vue.mixin({
//   created(){
//     var teste = this.$options.teste
//     if (teste) {
//       console.log(teste)
//     }
//   }
// })

store.state.menu = require('./Menu').default;

// String.prototype.validarCpf = cpf => {
//   return validar(cpf)
// }


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
