<template>
  <div>
    <table class="table table-sm table-hover table-striped" style="font-size:11px">
      <thead>
        <tr>
          <th>Venda</th>
          <th>Data</th>
          <th>Produto</th>
          <th>Paciente</th>
        </tr>
      </thead>
      <tbody
        v-for="g of lista"
        :key="`grp_det_${g.tipo}_${Math.random(829232)}`"
      >
        <tr>
          <td colspan="9999" class="bg-light">
            <h4>{{ g.tipo }} ({{ g.qtd }})</h4>
          </td>
        </tr>
        <tr v-for="item in g.lista" :key="`detalhe_${g.tipo}_${item.id}_${Math.random(28397)}`">
            <td>{{item.id}}</td>
            <td>{{item.data | moment("DD/MM/YYYY")}}</td>
            <td>{{item.descricao}}</td>
            <td>{{item.paciente}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    lista: Array,
  },
  mounted() {},
  data() {
    return {
      list: [],
    };
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>