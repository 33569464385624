import store from "../store";
import socketio from 'socket.io-client';
import VueSocketIO from 'vue-socket.io';

const create = async (Vue, url) => {
    const getToken = () => {
        return store.state.auth.user.token;
    }
    Vue.use(new VueSocketIO({
        debug: window.location.href.indexOf('localhost') > 0,
        // connection: socketio('https://api.saudesocial.net', { path: "/socket.io/", query: 'token=' + getToken() }),
        // connection: socketio('http://localhost:3333', { path: "/socket.io/", query: 'token=' + getToken() }),
        connection: socketio(url, { path: "/socket.io/", query: 'token=' + getToken() }),
        vuex: {
            store,
            actionPrefix: "SOCKET_",
            mutationPrefix: "SOCKET_"
        }
    })
    );

    console.log("SOCKET NOW CREATED AT ", url)
}


export default {
    create
}