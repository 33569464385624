<template>
  <div>
    <b-btn @click="openHistorico" v-bind="$attrs">
      <i class="fas fa-history"></i>
    </b-btn>

    <b-modal size="lg" id="__modal-logs">
      <template #modal-title>
        <i class="fas fa-history"></i> Logs
      </template>
      <ExibeLogs @close="closeHistorico" :id_externo="id_externo" :tabela="tabela" />
    </b-modal>
  </div>
</template>

<script>
export default {
  components: {
    ExibeLogs: () => import("./index.vue"),
  },
  props: {
    tabela: String,
    id_externo: [Number, String],
    namedKeys: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    openHistorico() {
      this.$bvModal.show('__modal-logs')
    },
    closeHistorico() {
      this.$bvModal.hide('__modal-logs')
    },
  },
};
</script>

<style></style>
