import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth';
import DBEspecialidades from './localStorage/Especialidades';
import DBEmpresas from './localStorage/Empresas';
// import sock from '../helpers/SocketIOCreateInstance';


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menuVisivel: false,
    menu: [],
    connection: null,
    version: '',
    globalLoading: false,
    // apiURL: "http://localhost:3333/",
    apiURL: "https://social.opservidor.com/",
    apiURLDef: "https://api.saudesocial.net/", 
    apiBKPURL: "https://bkp.saudesocial.net/"
  },
  mutations: {
    changeConnection(state, status) {
      state.connection = status;
    },
    changeDefaultAPIUrl(state, url) {
      console.log('change', url)

      Vue.prototype.$socket.io.uri = url;
      Vue.prototype.$socket.io.opts.hostname = url.split('//')[1].split(':')[0]
      // sock.create(Vue, url);
      // console.log('2socks--->', Vue.prototype.$socket)
      const axios = require('../utils/axios').default;
      console.log(axios.defaults);
      axios.defaults.baseURL = url;

      state.apiURL = url
      
    }
  },
  actions: {

  },
  modules: {
    auth,
    DBEspecialidades,
    DBEmpresas,

  }
})
