<template>
  <div class="w-100">
    <table
      class="table table-sm table-stipped text-left table-hover"
      style="font-size: 11px"
    >
      <thead>
        <tr>
          <th>Tipo</th>
          <th class="text-center">Quantidade</th>
          <th class="text-center">Valor</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="loading_premiada">
          <td colspan="999"><b-spinner small /> carregando...</td>
        </tr>
      </tbody>

      <tbody v-for="item in lista" :key="`itemRelat_${item.tp}`">
        <tr class="bg-light" style="">
          <th>{{ item.tp }}</th>
          <th class="text-center">{{ item.qtd }}</th>
          <th></th>
        </tr>

        <tr
          v-for="it in item.list"
          :key="`item_${item.tp}_${it.tipo}_${Math.random(28234)}`"
        >
          <td class="pl-4">{{ it.tipo }}</td>
          <td class="text-center">{{ it.qtd }}</td>
          <td class="text-center">
            {{ it.vl_total | currency }}
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="99" class="text-right">
            <b-btn variant="none" @click="$bvModal.show('modal-detalhe-premio')"
              ><i class="fa fa-list" /> Detalhado</b-btn
            >
          </td>
        </tr>
      </tfoot>
    </table>
    <b-modal id="modal-detalhe-premio" hide-footer title="Detalhes das Vendas Premiadas" size="lg">
        <Detalhes :lista="detalhes"/>
    </b-modal>

  </div>
</template>

<script>
import Detalhes from "./Detalhes.vue";
export default {
  components: { Detalhes },
  props: {
      lista:Array,
      detalhes:Array,
      loading_premiada:Boolean
  },
  mounted() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>