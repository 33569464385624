import axios from "../utils/axios";

const storeVenda = async (venda) => {
  try {
    return (await axios.post("/vendas/store", venda)).data;
  } catch (err) {
    console.log("ERRO AO SALVAR VENDA", err);

    return null;
  }
};

const buscarVendas = async (filters) => {
  try {
    //console.log('filters',filters)
    return (await axios.post("/vendas/details", filters)).data;
  } catch (err) {
    console.log("ERRO AO BUSAR VENDA", err);
    return null;
  }
};

const buscarVendasOpen = async (filters) => {
  try {
    //console.log('filters',filters)
    const ax = require('axios')
    return (await ax.post(axios.defaults.baseURL + "vendas-open/details", filters)).data;
  } catch (err) {
    console.log("ERRO AO BUSAR VENDA", err);
    return null;
  }
};

const cancelarVenda = async (id, observacao, cancelPix = false) => {
  if (!id) throw new Error("Informe o id da venda.");
  if (!observacao) throw new Error("Informe a observação.");
  try {
    return (
      await axios.post(`/vendas/cancelar/${id}`, { observacao: observacao, cancelPix })
    ).data;
  } catch (error) {
    console.log("erro ao cancelar venda", error.response);
    throw error.response.data.error;
  }
};

const getReceitas = async (id) => {
  if (!id) throw new Error("Informe o id da venda.");

  try {
    return (await axios.get(`/vendas/receitas/${id}`)).data;
  } catch (error) {
    console.log("erro ao buscar receitas da venda", error.response);
    throw error.response.data.error;
  }
};

const resync = async (id) => {
  try {
    return (await axios.get(`/vendas/resync/${id}`)).data;
  } catch (error) {
    console.log("erro ao resincronizar da venda", error);
  }
}
const alterarData = async (id, data) => {
  try {
    return (await axios.post(`/vendas/alterarData`, { id, data })).data;
  } catch (error) {
    console.log("erro ao resincronizar da venda", error);
  }
}
const alterarPagamento = async (id, old, pgto) => {
  try {
    return (await axios.post(`/vendas/alterarPagamento`, { id, old, pgto })).data;
  } catch (error) {
    console.log("erro ao alterarPagamento da venda", error);
  }
}
const alterarClinica = async (venda_id, empresa_id) => {
  try {
    return (await axios.post(`/vendas/alterarClinica`, { venda_id, empresa_id})).data;
  } catch (error) {
    console.log("erro ao alterarClinica da venda", error);
  }
}


const corrigirLancamentos = async (data) => {
  try {
    return (await axios.post(`/caixas/corrigirLancamentos`, data)).data;
  } catch (error) {
    console.log("erro ao corrigirLancamentos da venda", error, data);
  }
}

const corrigirLancamentosValidacao = async (data) => {
  try {
    return (await axios.post(`/caixas/corrigirLancamentosValidacao`, data)).data;
  } catch (error) {
    console.log("erro ao corrigirLancamentos da venda", error, data);
  }
}

const buscarPorID = async (type, id) => {
  try {
    return (await axios.post(`/vendas/findByID`, {type,id})).data;
  } catch (error) {
    console.log("erro ao findbyid venda", error, { type, id });
  }
}

const devolver = async (obj) => {
  try {
    return (await axios.post(`/vendas/devolver`, { obj })).data;
  } catch (error) {
    console.log("erro ao devolver venda", error, { obj });
  }
}
//

const getDevolucoes = async (filters) => {
  try {
    return (await axios.post(`/vendas/devolucoes`, filters)).data;
  } catch (error) {
    console.log("erro ao buscar devolucao da venda", error);
    throw error.response.data.error;
  }
}


export default {
  getDevolucoes,
  devolver,
  storeVenda,
  buscarVendas,
  cancelarVenda,
  getReceitas,
  resync,
  alterarData,
  alterarPagamento,
  alterarClinica,
  corrigirLancamentos,
  corrigirLancamentosValidacao,
  buscarPorID,
  buscarVendasOpen
};
