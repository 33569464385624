<template>
  <div
    style="
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      overflow-y: auto;
    "
  >
    <div class="text-left pt-2" v-if="item && item.paciente ">
      <div class="row">
        <div class="col-12 col-md-4 text-center">
          <img src="@/assets/logo.png" style="max-width: 100px" />
          <h1>Saúde Social</h1>
          <hr />
          <h4>Guia #{{ id }}</h4>
         
          <h1 class="text-success mt-5 mb-5" v-if="!venda.dt_cancelado"><b-icon-check-circle /> Válida</h1>
          <h1 class="text-danger mt-5 mb-5" v-if="venda.dt_cancelado"><b-icon-x-circle /> CANCELADO</h1>
          <hr />
        </div>
        <div class="col-12 col-md-8 text-center">
          <div class="p-4">
            <div class="card">
              <div class="card-body text-left">
                <div>
                  <b>Paciente</b><br />
                  {{ item.paciente }}
                </div>
                <div
                  v-if="item.data_consulta || (item.exames && item.exames.data)"
                >
                  <b>Data Consulta</b><br />
                  {{
                    item.data_consulta ||
                    item.exames.data | moment("DD/MM/YYYY")
                  }}
                  <span
                    v-if="
                      item.hora_consulta ||
                      (item.exames && item.exames.hora_consulta)
                    "
                  >
                    <b>-</b>
                    {{
                      item.hora_consulta ||
                      item.exames.hora_consulta | moment("DD/MM/YYYY")
                    }}
                  </span>
                </div>

                <hr />
                <div v-if="item.fornecedor">
                  <b>{{ item.fornecedor }}</b>
                  <br />
                  <div>
                    <div v-if="item.fornecedor_telefones">
                      <b>Telefone(s):</b>
                      <span
                        v-for="ender in item.fornecedor_telefones"
                        :key="`ender_${ender.telefone}`"
                      >
                        {{ ender.telefone }}
                        {{ item.fornecedor_telefones.length > 1 ? " | " : "" }}
                      </span>
                    </div>
                    <div v-if="item.fornecedor_enderecos">
                      <div
                        v-for="ender in item.fornecedor_enderecos"
                        :key="`ender_${ender.index}`"
                      >
                        <b
                          >Endereço
                          {{ item.fornecedor_enderecos.indexOf(ender) + 1 }}:
                        </b>
                        {{ ender.logradouro }}, {{ ender.numero }} -
                        {{ ender.bairro }} - {{ ender.cidade }} ({{ ender.uf }})
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div v-if="item.descricao">
                  <b>{{ item.descricao }}</b>
                  <div v-if="item.exames && item.exames.exames.length > 0">
                    <hr />
                    <div v-for="ex in item.exames.exames" :key="`ex_${ex.id}`">
                      <b-icon-dot />{{ ex.nome }}
                    </div>
                    <hr />
                    <div v-if="item.exames && item.exames.medicoNome">
                      <b>Solicitante</b> <br />
                      {{ item.exames.lbl }}
                    </div>
                  </div>
                </div>
                <div v-if="item.usuario">
                  <hr />
                  <b>Operador:</b>
                  {{ item.usuario }}
                  - {{ venda.clinica }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VendaLib from "../../libs/VendaLib";

//import VendaLib from '../../libs/VendaLib';

export default {
  components: {},
  props: {},
  mounted() {
    if (this.$route.params.id) {
      this.id = atob(this.$route.params.id);
      var clientInfo = "Informações do Cliente:\n";
      clientInfo += "Navegador: " + navigator.userAgent + "\n";
      clientInfo += "Idioma do Navegador: " + navigator.language + "\n";
      clientInfo += "Sistema Operacional: " + navigator.platform + "\n";
      clientInfo +=
        "Resolução da Tela: " + screen.width + "x" + screen.height + "\n";

      // Fazer uma solicitação HTTP para obter o endereço IP
      var request = new XMLHttpRequest();
      request.open("GET", "https://api.ipify.org?format=json", true);
      request.onload = ()=> {
        if (request.status >= 200 && request.status < 400) {
          var data = JSON.parse(request.responseText);
          clientInfo += "Endereço IP: " + data.ip + "\n";
          console.log(clientInfo);
          this.info = clientInfo
          this.carregar();
        }
      };
      request.onerror = ()=> {
        this.carregar();
      };
      request.send();
    }
  },
  data() {
    return {
      id: "",
      loading: false,
      item: null,
      venda: null,
      info: "",
    };
  },
  watch: {},
  computed: {},
  methods: {
    async carregar() {
      this.loading = true;
      console.log("req", {
          "venda_item.id": this.id,
          geraLog: this.info,
        })
      let venda = (
        await VendaLib.buscarVendasOpen({
          "venda_item.id": this.id,
          geraLog: { informacoes: this.info },
        })
      )[0];
      console.log("vendas", venda);

      if (venda) {
        this.venda = venda;
        let it = venda.itens.find((x) => x.id.toString() == this.id.toString());
        console.log("findID", it);
        if (it) {
          this.item = it;
        }
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
