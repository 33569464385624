import axios from "@/utils/axios";
const get = async (filtros) => {
  let result = [];
  await axios
    .get("usuarios/get", {params: filtros})
    .then((data) => {
      result = data.data;
    })
    .catch((err) => {
      console.log("erro ao buscar usuarios", err);
    });

  return result;
};

const store = async (data) => {
  return await axios
    .post("usuarios/store", data)
    .then((result) => {
      return result.data;
    })
    .catch(() => {
      return null;
    });
};

const del = async (user) => {
  return await axios
    .post("usuarios/delete", { user })
    .then((result) => {
      return result.data;
    })
    .catch(() => {
      return null;
    });
};

const changePassword = async (id, changePasswordRequest) => {
  return await axios.post(`usuarios/${id}/changePassword`, {
    changePasswordRequest,
  });
};

const getTipos = async () => {
  return (await axios.get('/usuarios/tipos')).data;
}

export default {
  get,
  store,
  del,
  changePassword,
  getTipos
};
