// import Vue from 'vue';
// import axios from '@/utils/axios';
import EmpresasLib from "../../libs/EmpresasLib";
const DBEmpresas = {
  namespaced: true,
  state: () => ({}),
  actions: {
    async refreshEmpresas() {
      const empresas = await EmpresasLib.getEmpresasGrupoAPI();
      localStorage.setItem("empresas", JSON.stringify(empresas));
    },
    async getEmpresa({ state, dispatch }, payload) {
      console.log('atualiza empresa')
      await dispatch('refreshEmpresas')
      state;
      //console.log('payload', payload);
      const id = payload?.id;
      const showInativas = payload?.showInativas;
      let listaStorage = JSON.parse(localStorage.getItem("empresas")) || []
      const lista = listaStorage.filter(
        (empresa) => {
          let result = true;
          if (id) {
            result = empresa.id == id;
          } else if (!showInativas) {
            result = empresa.status == 1;
          }

          return result;
        }
      );

      return lista;
    },
  },
};

export default DBEmpresas;
